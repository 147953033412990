<template>
    <div>
        <b-row>
            <b-col sm="12">
                <b-card>
                    <b-row>
                        <b-col sm="4" md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">{{$t('Date')}}</h5>
                                <flat-pickr v-model="date" class="form-control" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">{{$t('Start Time')}}</h5>
                                <flat-pickr v-model="startTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}" />
                            </b-form-group>
                        </b-col>
                        <b-col sm="4" md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">{{$t('End Time')}}</h5>
                                <flat-pickr v-model="endTime" class="form-control" :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i',}" />
                            </b-form-group>
                        </b-col>
                        <!--Btn Send-->
                        <b-col sm="4" md="3" class="align-middle">
                            <b-button :disabled="loading" class="mt-2 mb-2" variant="primary" type="button" @click="composeReport">
                                {{$t('Send')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    
        <b-row>
            <b-col sm="12">
                <b-card >
                    <b-row>
                        <b-col sm="12">
                            <l-map ref="harvestMap" :zoom="zoom" :center="center" :key="mapKey">
                                    <l-tile-layer :url="url"></l-tile-layer>
                                    <l-control position="topright">
                                        <!-- size -->
                                        <b-button-group size="sm">
                                            <b-button variant="light" :pressed="btnMap" @click="chooseLayer('map')">
                                                Map
                                            </b-button>
                                            <b-button variant="light" :pressed="btnGround" @click="chooseLayer('ground')">
                                                Ground
                                            </b-button>
                                            <b-button variant="light" :pressed="btnHybrid" @click="chooseLayer('hybrid')">
                                                Hybrid
                                            </b-button>
                                        </b-button-group>
                                    </l-control>
                                    <l-control position="topright">
                                        <b-button  @click="showTelemetry = !showTelemetry"  variant="primary" size="sm" >
                                            <span v-show="!showTelemetry">Fixar Telemetria</span>
                                            <span v-show="showTelemetry">Ocultar Telemetria</span>
                                        </b-button>
                                    </l-control>
            
                                    <l-marker ref="pins" v-for="(asset, i) in assetsData" :lat-lng="[asset.lat, asset.lng]" @mouseover="hoveringAssetIndex = i" @mouseout="hoveringAssetIndex = -1">
                                        <l-icon class-name="pin">
                                            <div class="marker-content">
                                                <span v-if="i == 0" class="marker-icon">🚩</span>
                                                <span v-else-if="i == assetsData.length - 1" class="marker-icon">🏁</span>
                                                <span v-else class="marker-index"></span>
                                            </div>
                                        </l-icon>
                                        <l-tooltip v-if="showTelemetry || hoveringAssetIndex == i" :options="{ permanent: true, interactive: false }">
                                            <div>
                                                <div>⏰ {{$t('Time')}}: <strong>{{new Date(asset.dateTime).toLocaleTimeString().substring(0, 5)}}</strong></div>
                                                <div v-for="(value, key) in asset.data" :key="key">
                                                    <span><strong>{{ key }}: </strong> {{ value }}</span>
                                                </div>
                                            </div>
                                        </l-tooltip>

                                    </l-marker>
                                    <l-polyline :opacity="0.6" :lat-lngs="latlngs" color="#4285f4"></l-polyline>
                            </l-map>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

         <!--Modal Create Export-->
        <b-modal ref="modal-report" size="sm" centered hide-header hide-footer no-close-on-esc no-close-on-backdrop hide-header-close>
            <div class="mx-3 text-center">
                <h3>{{$t('Preparing report')}}...</h3>
                <b-spinner variant="primary" label="Text Centered" />
            </div>
        </b-modal>
    </div>

</template>
    
<script>
    
    
    import {
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,
        BAvatar,
        BPagination,
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BIcon,
        BInputGroup,
        BInputGroupAppend,
        BSpinner,
        BModal,
        BButtonGroup
    } from 'bootstrap-vue'

    import { ref, onUnmounted  } from '@vue/composition-api'

    import router from '@/router'

    import store from '@/store'

    import queriesStoreModule from '../queriesStoreModule'

    import "leaflet/dist/leaflet.css";

    import {
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LPopup,
        LPolyline,
        LTooltip,
        LControl,
    } from "vue2-leaflet";

    import L from 'leaflet';

    import flatPickr from 'vue-flatpickr-component'

    
    export default {
        components: {
            BTab,
            BTabs,
            BCard,
            BAlert,
            BLink,
            BFormInput,
            BFormGroup,
            BForm,
            BRow,
            BCol,
            BButton,
            BCardText,
            BFormTextarea,
            BNavItemDropdown,
            BDropdownItem,
            BImg,
            BAvatar,
            BPagination,
            BIcon,
            BInputGroup,
            BInputGroupAppend,
            BSpinner,
            BModal,
            BButtonGroup,

            //Leafleet Map
            LMap,
            LTileLayer,
            LMarker,
            LIcon,
            LPopup,
            LPolyline,
            LTooltip,
            LControl,

            //FlatPicker
            flatPickr

    
        },
        data() {
            return {    

                hoveringAssetIndex: -1,

                btnGround: false,
                btnMap: true,
                btnHybrid: false,

                titleTootipBtnTelemetry: 'Mostrar Telemetria',
                showTelemetry: false,
                loading: false,
                date: '',
                startTime: '',
                endTime: '',
                page: 1,
                assetsData: [
                   
                ],
                latlngs: [
                  
                ],
                url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                zoom: 4,
                mapKey: 'harvestmap',
                selectedLayer: localStorage.selectedLayer != undefined ? localStorage.selectedLayer : "map",

                center: [-12.4131269, -55.5261856],
                
                icon: L.icon({
                        iconSize: [32, 37],
                        iconAnchor: [16, 37]
                    }),
                
                availableParameters: [],
                data:{}

            }
        },
        mounted() {

           
        },
        watch: {
            selectedLayer(newVal) {
                localStorage.selectedLayer = newVal;
            },
            showTelemetry(newVal) {
                if (!newVal) {
                    this.clearTooltip();
                }
            },
            hoveringAssetIndex(newIndex) {

                if (newIndex === -1 && !this.showTelemetry) {
                    this.clearTooltip();
                }
                
            },
        },
        computed:{
            chooseUrl() {
                if (this.selectedLayer == "map")
                    return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
                if (this.selectedLayer == "hybrid")
                    //return "https://1.aerial.maps.api.here.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&app_id=adPipTPyffSRGrMtXttz&app_code=oNecq1kBuaikCv_pYw20yg";
                    return "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&apiKey=U7wvT2IU0rFTeGwMoIpdf7JaQx5QH74SyhG4qiHy7Q8"
                if (this.selectedLayer == "ground")
                    return "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
            },
        },
        methods: {

            chooseLayer(url) {
            

            if (url == `ground`) {
                this.btnGround = true
                this.btnMap = false
                this.btnHybrid = false
                this.url = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
            }
            if (url == `map`) {
                this.btnGround = false
                this.btnMap = true
                this.btnHybrid = false
                this.url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            }
            if (url == `hybrid`) {
                this.btnGround = false
                this.btnMap = false
                this.btnHybrid = true
                this.url = "https://2.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{z}/{x}/{y}/256/png8?lg=por&apiKey=U7wvT2IU0rFTeGwMoIpdf7JaQx5QH74SyhG4qiHy7Q8"
                    
            }
           
        },

            clearTooltip() {
                const tooltips = document.querySelectorAll('.leaflet-tooltip');
                tooltips.forEach((tooltip) => {
                    tooltip.style.display = 'none';
                });
            },

            showModalReport() {
                this.$refs['modal-report'].show()
            },
            hideModalReport() {
                this.$refs['modal-report'].hide()
            },

            async composeReport(){
               //await this.fetchSearchAvailableParameters()
               await this.searchTelemetry()
            },

            async fetchSearchAvailableParameters(){
                var date = new Date(this.date);
                date.setDate(date.getDate() - 1);
                var initalDate = date.toISOString().slice(0, 10);
                let response = await store.dispatch('app-queries/fetchSearchAvailableParameters', {
                            assetId: this.assetId,
                            initalDate: initalDate,
                            finalDate: this.date
                        });
                this.availableParameters = response.data.data
                console.log("Resposta avaible: ", response)
            },

            async searchTelemetry(){
                

                var cf = false
                var page = 1
                this.assetsData = []
                this.latlngs = []
                this.loading = true
                this.showModalReport()

                try{
                    while (cf == false) {
                        let response = await store.dispatch('app-queries/searchTelemetry', {
                            assetId: this.assetId,
                            initalDate: this.formatDateToIso(this.date, this.startTime),
                            finalDate: this.formatDateToIso(this.date, this.endTime),
                            page: page
                        });

                        if (response && response.data.data.length > 0) {
                            response.data.data.map(r => {
                                if (r.lat && r.lat != 0 && r.lng && r.lng != 0) {
                                    this.assetsData.push(r)
                                    this.latlngs.push([r.lat, r.lng])
                                }
                            });

                        } else {

                            cf = true
                            this.loading = false
                            this.hideModalReport()

                            if (this.latlngs.length > 0) {
                                this.$nextTick(() => {
                                    //fitBounds flyToBounds
                                    this.$refs.harvestMap.mapObject.fitBounds(
                                        this.latlngs.map((m, i) => {
                                            return [m[0], m[1]];
                                        })
                                    );
                                })
                            }
                            else{
                                this.msgDialog('Nenhum resultado encontrado', 'info')
                            }
                        }
                        page++
                    }

                }catch(e){
                    this.assetsData = []
                    this.latlngs = []
                    cf = true
                    this.loading = false
                    this.hideModalReport()
                    this.msgDialog('Ocorreu um erro inesperado', 'error')
                }
            },

            formatDateToIso(date, hour){
                var dateISO = date + 'T' + hour;
                return dateISO
            },

            async fetchReport() {

                try{
                    var cf = false
                    var page = 1

                    this.assetsData = []
                    this.latlngs = []

                    this.loading = true
                    this.showModalReport()

                    while (cf == false) {

                        const res = await store.dispatch('app-queries/fetchTelemetrylocationhistory', {
                            id: this.assetId,
                            date: this.date,
                            hS: this.startTime,
                            hE: this.endTime,
                            page: page
                        });

                        console.log('resp: ', res)

                        if (res && res.data.data.length > 0) {
                            res.data.data.map(r => {
                                if (r.lat && r.lat != 0 && r.lng && r.lng != 0) {
                                    this.assetsData.push(r)
                                    this.latlngs.push([r.lat, r.lng])
                                }
                            });

                        } else {

                            cf = true
                            this.loading = false
                            this.hideModalReport()

                            if (this.latlngs.length > 0) {
                                this.$nextTick(() => {
                                    //fitBounds flyToBounds
                                    this.$refs.harvestMap.mapObject.fitBounds(
                                        this.latlngs.map((m, i) => {
                                            return [m[0], m[1]];
                                        })
                                    );
                                })
                            }
                            else{
                                this.msgDialog('Nenhum resultado encontrado', 'info')
                            }
                        }
                        page++
                    }
                }
                catch(e){
                    this.assetsData = []
                    this.latlngs = []
                    cf = true
                    this.loading = false
                    this.hideModalReport()
                    this.msgDialog('Ocorreu um erro inesperado', 'error')
                }

                

            },
                     
            msgDialog(msg, icon) {
                this.$swal({
                    position: 'top-end',
                    icon: icon,
                    title: icon,
                    text: msg,
                    showConfirmButton: false,
                    timer: 1500 * 3,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },

                    buttonsStyling: false,
                })
            },

    
        },
        setup() {
           
            const QUERIES_APP_STORE_MODULE_NAME = 'app-queries'         
            // Register module
            if (!store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.registerModule(QUERIES_APP_STORE_MODULE_NAME, queriesStoreModule)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(QUERIES_APP_STORE_MODULE_NAME)) store.unregisterModule(QUERIES_APP_STORE_MODULE_NAME)
            })

            const assetId = ref(router.currentRoute.params.id)
                
                return {
                    QUERIES_APP_STORE_MODULE_NAME,
                    assetId
        
                }
            },
        }
    </script>
    
    <style lang="scss">
        
        @import '@core/scss/vue/libs/map-leaflet.scss';
        @import '@core/scss/vue/libs/vue-flatpicker.scss';

        .pin {
            background-color: #4285f4 !important;
            /*padding: 10px;*/
            border-radius: 50%;
            /*box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);*/
            text-align: center;
            width: 16px !important;
            height: 16px !important;
        }

        .marker-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 0;
        }

        .marker-icon {
            color: #4285f4;
            font-size: 20px;
        }

        .marker-index {
            color: #4285f4;
        }

        .vue2leaflet-map{
            &.leaflet-container{
                height: 55vh !important;
            }
        }
    </style>
    